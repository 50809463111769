/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'

import ROUTES from 'constants/routes'
import { ALL_ROLES, ROLES } from 'constants/enums'

import { CurrentUserContext } from 'contexts/CurrentUserContext'
import { DefaultSettingsContext } from 'contexts/DefaultSettingsContext'

const { ROLE_ADMIN, ROLE_MANAGER, ROLE_USER } = ROLES

const MENU_ITEMS = [
    {
        key: 'home',
        label: 'menu.home',
        icon: 'home',
        to: ROUTES.HOME,
        roles: ALL_ROLES,
    },
    {
        key: 'pib',
        label: 'menu.pib',
        icon: 'pib',
        to: ROUTES.PIB,
        roles: ALL_ROLES,
        subitems: [
            {
                key: 'airportPib',
                label: 'menu.airport',
                to: `${ROUTES.PIB}${ROUTES.AIRPORT}`,
                icon: 'plane-arrival',
            },
            {
                key: 'areaPib',
                label: 'menu.area',
                to: `${ROUTES.PIB}${ROUTES.AREA}`,
                icon: 'area',
            },
            {
                key: 'routePib',
                label: 'menu.route',
                to: `${ROUTES.PIB}${ROUTES.ROUTE}`,
                icon: 'route',
            },
            {
                key: 'narrowRoutePib',
                label: 'menu.narrow',
                to: `${ROUTES.PIB}${ROUTES.NARROW}`,
                icon: 'narrow',
            },
            {
                key: 'pointPib',
                label: 'menu.point',
                to: `${ROUTES.PIB}${ROUTES.POINT}`,
                icon: 'point',
            },
            {
                key: 'handbook',
                label: 'menu.handbook',
                to: `${ROUTES.PIB}${ROUTES.HANDBOOK}`,
                icon: 'handbook',
            },
        ],
    },
    {
        key: 'fpl',
        label: 'menu.fpl',
        icon: 'fpl',
        to: ROUTES.FPL,
        roles: ALL_ROLES,
        subitems: [
            {
                key: 'create',
                label: 'menu.fplCreate',
                to: `${ROUTES.FPL}${ROUTES.CREATE}`,
                icon: 'square-plus',
            },
            {
                key: 'overview',
                label: 'menu.fplOverview',
                to: `${ROUTES.FPL}${ROUTES.OVERVIEW}`,
                icon: 'overview',
            },
            {
                key: 'template',
                label: 'menu.fplTemplate',
                to: `${ROUTES.FPL}${ROUTES.TEMPLATE}`,
                icon: 'template',
            },
        ],
    },
    {
        key: 'aipLibrary',
        label: 'menu.aip',
        icon: 'library',
        to: ROUTES.AIP,
        roles: ALL_ROLES,
    },
    {
        key: 'admin',
        label: 'menu.admin',
        icon: 'administration',
        to: ROUTES.ADMIN,
        checkSubitems: true,
        roles: [ROLE_ADMIN, ROLE_MANAGER],
        subitems: [
            {
                key: 'users',
                label: 'menu.userManagement',
                to: `${ROUTES.ADMIN}${ROUTES.USERS}`,
                roles: [ROLE_ADMIN],
                icon: 'users',
            },
            {
                key: 'translations',
                label: 'menu.translations',
                to: `${ROUTES.ADMIN}${ROUTES.TRANSLATIONS}`,
                roles: [ROLE_ADMIN],
                icon: 'translation',
            },
            {
                key: 'feedback',
                label: 'menu.feedback',
                to: `${ROUTES.ADMIN}${ROUTES.FEEDBACK}`,
                roles: [ROLE_ADMIN],
                icon: 'feedback',
            },
            {
                key: 'cms',
                label: 'menu.cms',
                to: `${ROUTES.ADMIN}${ROUTES.PAGES}`,
                roles: [ROLE_ADMIN],
                icon: 'cms',
            },
            {
                key: 'notices',
                label: 'menu.notices',
                to: `${ROUTES.ADMIN}${ROUTES.NOTICES}`,
                roles: [ROLE_ADMIN],
                icon: 'bell',
            },
            {
                key: 'group',
                label: 'menu.group',
                to: `${ROUTES.ADMIN}${ROUTES.GROUPS}`,
                roles: [ROLE_ADMIN, ROLE_MANAGER],
                icon: 'group',
            },
            {
                key: 'settings',
                label: 'menu.settings',
                to: `${ROUTES.ADMIN}${ROUTES.SETTINGS}`,
                roles: [ROLE_ADMIN],
                icon: 'settings',
            },
            {
                key: 'menu_display_settings',
                label: 'menu.menuDisplaySettings',
                to: `${ROUTES.ADMIN}${ROUTES.MENU_DISPLAY_SETTINGS}`,
                roles: [ROLE_ADMIN],
                icon: 'site-services',
            },
            {
                key: 'logs',
                label: 'menu.logs',
                to: `${ROUTES.ADMIN}${ROUTES.LOGS}`,
                roles: [ROLE_ADMIN],
                icon: 'logs',
            },
        ],
    },
    {
        key: 'statistics',
        label: 'menu.statistics',
        icon: 'statistics',
        to: ROUTES.STATISTICS,
        roles: [ROLE_ADMIN],
        subitems: [
            {
                key: 'statisticsUsers',
                label: 'menu.statisticsUsers',
                to: `${ROUTES.STATISTICS}${ROUTES.USERS}`,
                icon: 'statistics-user',
            },
            {
                key: 'statisticsPreFlight',
                label: 'menu.statisticsPreFlight',
                to: `${ROUTES.STATISTICS}${ROUTES.PREFLIGHTS}`,
                icon: 'statistics-preflight',
            },
            {
                key: 'statisticsFlight',
                label: 'menu.statisticsFlight',
                to: `${ROUTES.STATISTICS}${ROUTES.FLIGHTS}`,
                icon: 'statistics-flight',
            },
        ],
    },
    {
        key: 'requests',
        label: 'menu.requests',
        icon: 'requests',
        to: ROUTES.GROUP_REQUESTS,
        roles: [ROLE_MANAGER, ROLE_USER],
    },
    {
        key: 'personal',
        label: 'menu.personal',
        icon: 'user',
        to: ROUTES.PERSONAL,
        roles: ALL_ROLES,
        subitems: [
            {
                key: 'personalAerodromes',
                label: 'menu.personalAerodromes',
                to: `${ROUTES.PERSONAL}${ROUTES.AERODROMES}`,
                icon: 'plane-departure',
            },
            {
                key: 'personalFIRS',
                label: 'menu.personalFIRS',
                to: `${ROUTES.PERSONAL}${ROUTES.FIRS}`,
                icon: 'personal-firs',
            },
            {
                key: 'personalRoutes',
                label: 'menu.personalRoutes',
                to: `${ROUTES.PERSONAL}${ROUTES.ROUTES}`,
                icon: 'personal-routes',
            },
            {
                key: 'personalSettings',
                label: 'menu.personalSettings',
                to: `${ROUTES.PERSONAL}${ROUTES.SETTINGS}`,
                icon: 'settings',
            },
            {
                key: 'feedback',
                label: 'menu.personalFeedback',
                to: `${ROUTES.PERSONAL}${ROUTES.FEEDBACK}`,
                icon: 'feedback',
            },
        ],
    },
]

const useMenuItems = () => {
    const { currentUser } = useContext(CurrentUserContext)
    const { menuDisplaySettings, cmsPages } = useContext(DefaultSettingsContext)

    const [menuItems, setMenuItems] = useState([])

    useEffect(() => {
        if (currentUser && menuDisplaySettings) {
            filterMenuItems()
        }
    }, [currentUser, menuDisplaySettings, cmsPages])

    const filterMenuItems = () => {
        const userSettings = currentUser.setting
        const filteredMenuItems = MENU_ITEMS.reduce(
            (acc, { subitems, ...item }) => {
                const hasPermission = item.roles.some(
                    (role) => role.name === currentUser.role.name
                )
                if (
                    hasPermission &&
                    menuDisplaySettings[item.key] !== false &&
                    userSettings[item.key] !== false
                ) {
                    let menuItem = { ...item }
                    if (item.checkSubitems && subitems) {
                        menuItem.subitems = subitems.filter(
                            (subitem) =>
                                subitem.roles.some(
                                    (role) =>
                                        role.name === currentUser.role.name
                                ) &&
                                menuDisplaySettings[subitem.key] !== false &&
                                userSettings[subitem.key] !== false
                        )
                    } else if (subitems) {
                        menuItem.subitems = subitems.filter((subitem) => {
                            return (
                                menuDisplaySettings[subitem.key] !== false &&
                                userSettings[subitem.key] !== false
                            )
                        })
                    }
                    acc.push(menuItem)
                }
                return acc
            },
            []
        )

        const cmsMenuItems = cmsPages
            .filter((el) => el.id !== 'home')
            .map(({ id, title }) => ({
                key: id,
                label: title,
                icon: 'note',
                to: `${ROUTES.CMS}/${id}`,
                roles: ALL_ROLES,
                noTranslation: true,
            }))

        setMenuItems([...filteredMenuItems, ...cmsMenuItems])
    }

    return menuItems
}

export default useMenuItems
