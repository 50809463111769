import React, { Fragment, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import loadable from 'utils/loadable'

import ROUTES from 'constants/routes'

import Layout from 'components/Layout'
import { ROLES } from 'constants/enums'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

const Home = loadable(() => import('screens/Home'))

const DefaultSiteServices = loadable(() =>
    import('screens/admin/DefaultSiteServices')
)

const UsersManagement = loadable(() => import('screens/admin/UsersManagement'))
const EditUser = loadable(() =>
    import('screens/admin/UsersManagement/EditUser')
)

const GroupsManagement = loadable(() =>
    import('screens/admin/GroupsManagement')
)
const CreateGroup = loadable(() =>
    import('screens/admin/GroupsManagement/CreateGroup')
)
const EditGroup = loadable(() =>
    import('screens/admin/GroupsManagement/EditGroup')
)

const TranslationsManagement = loadable(() =>
    import('screens/admin/TranslationsManagement')
)
const EditTranslation = loadable(() =>
    import('screens/admin/TranslationsManagement/EditTranslation')
)
const Logs = loadable(() => import('screens/admin/Logs'))
const NoticeBoard = loadable(() => import('screens/admin/NoticeBoard'))
const CreateNotice = loadable(() =>
    import('screens/admin/NoticeBoard/CreateNotice')
)
const EditNotice = loadable(() =>
    import('screens/admin/NoticeBoard/EditNotice')
)
const CmsPages = loadable(() => import('screens/admin/CmsPages'))
const CreatePage = loadable(() => import('screens/admin/CmsPages/CreatePage'))
const EditPage = loadable(() => import('screens/admin/CmsPages/EditPage'))
const PagePreview = loadable(() => import('screens/admin/CmsPages/PagePreview'))
const Feedback = loadable(() => import('screens/Feedback'))
const CreateFeedback = loadable(() => import('screens/Feedback/CreateFeedback'))
const EditFeedback = loadable(() => import('screens/Feedback/EditFeedback'))

const PersonalSettings = loadable(() => import('screens/user/PersonalSettings'))
const PersonalAerodromes = loadable(() =>
    import('screens/user/PersonalAerodromes')
)
const CreatePersonalAerodrome = loadable(() =>
    import('screens/user/PersonalAerodromes/CreatePersonalAerodrome')
)
const EditPersonalAerodrome = loadable(() =>
    import('screens/user/PersonalAerodromes/EditPersonalAerodrome')
)
const PersonalFirs = loadable(() => import('screens/user/PersonalFirs'))
const CreatePersonalFir = loadable(() =>
    import('screens/user/PersonalFirs/CreatePersonalFir')
)
const EditPersonalFir = loadable(() =>
    import('screens/user/PersonalFirs/EditPersonalFir')
)
const PersonalRoutes = loadable(() => import('screens/user/PersonalRoutes'))
const CreatePersonalRoute = loadable(() =>
    import('screens/user/PersonalRoutes/CreatePersonalRoute')
)
const EditPersonalRoute = loadable(() =>
    import('screens/user/PersonalRoutes/EditPersonalRoute')
)

const DefaultSettings = loadable(() => import('screens/admin/DefaultSettings'))

const Requests = loadable(() => import('screens/Requests'))
const CreateRequest = loadable(() => import('screens/Requests/CreateRequest'))

const FlightplanOverview = loadable(() =>
    import('screens/fpl/FlightplanOverview')
)
const CreateFlightplan = loadable(() => import('screens/fpl/CreateFlightplan'))
const EditFlightplan = loadable(() => import('screens/fpl/EditFlightplan'))
const ShowFlightplan = loadable(() => import('screens/fpl/ShowFlightplan'))
const FlightplanTemplate = loadable(() =>
    import('screens/fpl/FlightplanTemplate')
)
const CreateFplTemplate = loadable(() =>
    import('screens/fpl/FlightplanTemplate/CreateFplTemplate')
)
const EditFplTemplate = loadable(() =>
    import('screens/fpl/FlightplanTemplate/EditFplTemplate')
)

const AerodromePIB = loadable(() => import('screens/pib/AerodromePIB'))
const AreaPIB = loadable(() => import('screens/pib/AreaPIB'))
const RoutePIB = loadable(() => import('screens/pib/RoutePIB'))
const NarrowRoutePIB = loadable(() => import('screens/pib/NarrowRoutePIB'))
const PointPIB = loadable(() => import('screens/pib/PointPIB'))
const BriefingHandbook = loadable(() => import('screens/pib/BriefingHandbook'))

const AIPLibrary = loadable(() => import('screens/AIPLibrary'))

const UserStatistics = loadable(() =>
    import('screens/Statistics/UserStatistics')
)
const PreFlightsStatistics = loadable(() =>
    import('screens/Statistics/PreFlightsStatistics')
)
const FlightsStatistics = loadable(() =>
    import('screens/Statistics/FlightsStatistics')
)
const CmsPage = loadable(() => import('screens/CmsPage'))

const { ROLE_ADMIN, ROLE_MANAGER, ROLE_USER } = ROLES

const AuthRoutes = () => {
    const { currentUser } = useContext(CurrentUserContext)

    if (!currentUser) return null

    const renderAllowedRoutes = () => {
        switch (currentUser.role.name) {
            case ROLE_ADMIN.name:
                return (
                    <Fragment>
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.MENU_DISPLAY_SETTINGS}`}
                            element={<DefaultSiteServices />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.SETTINGS}`}
                            element={<DefaultSettings />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.SETTINGS}/:tab`}
                            element={<DefaultSettings />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.USERS}`}
                            element={<UsersManagement />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.USERS}/:id/:tab`}
                            element={<EditUser />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.GROUPS}`}
                            element={<GroupsManagement />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.GROUPS}${ROUTES.CREATE}`}
                            element={<CreateGroup />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.GROUPS}/:id`}
                            element={<EditGroup />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.TRANSLATIONS}`}
                            element={<TranslationsManagement />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.TRANSLATIONS}/:id`}
                            element={<EditTranslation />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.LOGS}`}
                            element={<Logs />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.NOTICES}`}
                            element={<NoticeBoard />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.NOTICES}${ROUTES.CREATE}`}
                            element={<CreateNotice />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.NOTICES}/:id`}
                            element={<EditNotice />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.PAGES}`}
                            element={<CmsPages />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.PAGES}${ROUTES.CREATE}`}
                            element={<CreatePage />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.PAGES}/:id`}
                            element={<EditPage />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.PAGES}${ROUTES.PREVIEW}/:id`}
                            element={<PagePreview />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.FEEDBACK}`}
                            element={<Feedback />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.FEEDBACK}${ROUTES.CREATE}`}
                            element={<CreateFeedback />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.FEEDBACK}/:id`}
                            element={<EditFeedback />}
                            exact
                        />
                    </Fragment>
                )
            case ROLE_MANAGER.name:
                return (
                    <Fragment>
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.GROUPS}`}
                            element={<GroupsManagement />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.ADMIN}${ROUTES.GROUPS}/:id`}
                            element={<EditGroup />}
                            exact
                        />

                        <Route
                            path={ROUTES.GROUP_REQUESTS}
                            element={<Requests />}
                            exact
                        />
                    </Fragment>
                )
            case ROLE_USER.name:
            default:
                return (
                    <Fragment>
                        <Route
                            path={ROUTES.GROUP_REQUESTS}
                            element={<Requests />}
                            exact
                        />
                        <Route
                            path={`${ROUTES.GROUP_REQUESTS}${ROUTES.CREATE}`}
                            element={<CreateRequest />}
                            exact
                        />
                    </Fragment>
                )
        }
    }

    return (
        <Layout>
            <Routes>
                {/* Common routes */}
                <Route path={ROUTES.HOME} element={<Home />} exact />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.SETTINGS}`}
                    element={<PersonalSettings />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.AERODROMES}`}
                    element={<PersonalAerodromes />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.AERODROMES}${ROUTES.CREATE}`}
                    element={<CreatePersonalAerodrome />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.AERODROMES}/:id`}
                    element={<EditPersonalAerodrome />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FIRS}`}
                    element={<PersonalFirs />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FIRS}${ROUTES.CREATE}`}
                    element={<CreatePersonalFir />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FIRS}/:id`}
                    element={<EditPersonalFir />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.ROUTES}`}
                    element={<PersonalRoutes />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.ROUTES}${ROUTES.CREATE}`}
                    element={<CreatePersonalRoute />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.ROUTES}/:id`}
                    element={<EditPersonalRoute />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FEEDBACK}`}
                    element={<Feedback />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FEEDBACK}${ROUTES.CREATE}`}
                    element={<CreateFeedback />}
                    exact
                />
                <Route
                    path={`${ROUTES.PERSONAL}${ROUTES.FEEDBACK}/:id`}
                    element={<EditFeedback />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.OVERVIEW}`}
                    element={<FlightplanOverview />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.CREATE}`}
                    element={<CreateFlightplan />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}/:id`}
                    element={<EditFlightplan />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.SHOW}/:id`}
                    element={<ShowFlightplan />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.TEMPLATE}`}
                    element={<FlightplanTemplate />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.TEMPLATE}${ROUTES.CREATE}`}
                    element={<CreateFplTemplate />}
                    exact
                />
                <Route
                    path={`${ROUTES.FPL}${ROUTES.TEMPLATE}/:id`}
                    element={<EditFplTemplate />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.AIRPORT}`}
                    element={<AerodromePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.AIRPORT}/:tab`}
                    element={<AerodromePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.AREA}`}
                    element={<AreaPIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.AREA}/:tab`}
                    element={<AreaPIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.ROUTE}`}
                    element={<RoutePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.ROUTE}/:tab`}
                    element={<RoutePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.NARROW}`}
                    element={<NarrowRoutePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.NARROW}/:tab`}
                    element={<NarrowRoutePIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.POINT}`}
                    element={<PointPIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.POINT}/:tab`}
                    element={<PointPIB />}
                    exact
                />
                <Route
                    path={`${ROUTES.PIB}${ROUTES.HANDBOOK}`}
                    element={<BriefingHandbook />}
                    exact
                />
                <Route path={ROUTES.AIP} element={<AIPLibrary />} exact />
                <Route
                    path={`${ROUTES.STATISTICS}${ROUTES.USERS}`}
                    element={<UserStatistics />}
                    exact
                />
                <Route
                    path={`${ROUTES.STATISTICS}${ROUTES.PREFLIGHTS}`}
                    element={<PreFlightsStatistics />}
                    exact
                />
                <Route
                    path={`${ROUTES.STATISTICS}${ROUTES.FLIGHTS}`}
                    element={<FlightsStatistics />}
                    exact
                />
                <Route path={`${ROUTES.CMS}/:id`} element={<CmsPage />} exact />
                {renderAllowedRoutes()}
                <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
            </Routes>
        </Layout>
    )
}

export default AuthRoutes
