const ENTITY = {
    USER: 'users',
    LANGUAGE: 'languages',
    COUNTRY: 'get_countries',
    TRANSLATION: 'translations',
    DEFAULT_SETTING: 'default_settings',
    MENU_DISPLAY_SETTING: 'menu_display_settings',
    ROLE: 'roles',
    GROUP: 'groups',
    EXPORT_USERS: 'export-users',
    EXPORT_GROUPS: 'export-groups',
    DELETE_USER: 'delete-user',
    GROUP_REQUEST: 'group_requests',
    REMOVE_USER_GROUP: 'remove-user-group',
    FLIGHTPLAN: 'find-flight-plans',
    AIRCRAFT_TYPE: 'find-aircraft-types',
    AIRSPACE: 'rest/airspace/type/FIR',
    MAP_ROUTE: 'rest/routes/route',
    AERODROME: 'find-aerodromes',
    SIGNIFICANT_POINT: 'find-significant-points',
    FLIGHTPLAN_PROPOSE_SEND: 'propose-send-flight-plan',
    FLIGHTPLAN_TEMPLATE_SAVE: 'save-flight-plan-user-template',
    FLIGHTPLAN_TEMPLATE: 'find-flight-plan-user-templates',
    FLIGHTPLAN_PROPOSE_CANCEL: 'propose-cancel-flight-plan',
    FLIGHTPLAN_PROPOSE_DELAY: 'propose-delay-flight-plan',
    FLIGHTPLAN_PROPOSE_DEP_MESSAGE: 'propose-dep-message',
    FLIGHTPLAN_PROPOSE_ARR_MESSAGE: 'propose-arr-message',
    GET_FLIGHTPLAN: 'get-flight-plan',
    FLIGHTPLAN_PROPOSE_CHANGE: 'propose-change-flight-plan',
    DELETE_FLIGHTPLAN_TEMPLATE: 'delete-flight-plan-user-template',
    EDIT_FLIGHTPLAN_TEMPLATE: 'edit-flight-plan-user-template',
    FIRS: 'find-firs',
    CREATE_AERODROME_PIB: 'create-aerodrome-pib',
    CREATE_AREA_PIB: 'create-area-pib',
    CREATE_ROUTE_PIB: 'create-route-pib',
    CREATE_NARROW_ROUTE_PIB: 'create-narrow-route-pib',
    CREATE_POINT_PIB: 'create-point-pib',
    PIB_REQUESTS: 'get-pib-requests',
    UPDATE_PIB: 'generate-update-pib',
    SEND_PIB_EMAIL: 'send-pib-email',
    GET_PERSONAL_OBJECT: 'find-personal-sdoo-objects',
    CREATE_PERSONAL_OBJECT: 'insert-personal-sdo-objects',
    EDIT_PERSONAL_OBJECT: 'update-personal-sdo-objects',
    DELETE_PERSONAL_OBJECT: 'delete-personal-sdo-objects',
    GET_PERSONAL_ROUTES: 'find-personal-routes',
    CREATE_PERSONAL_ROUTE: 'insert-personal-routes',
    EDIT_PEERSONAL_ROUTE: 'update-personal-routes',
    DELETE_PERSONAL_ROUTE: 'delete-personal-routes',
    GDPR_LOG: 'user-gdpr-log',
    NOTICE: 'notices',
    FEEDBACK: 'feedback',
    EXPORT_FEEDBACK: 'export-feedback',
    AIP_LIBRARY: 'pams-search',
    AIP_DOWNLOAD: 'pams-download',
    STATISTIC_USER: 'statistics-users',
    STATISTIC_FLIGHT: 'statistics-flights',
    STATISTIC_PREFLIGHT: 'statistics-preflights',
    PAGE: 'pages',
}

export default ENTITY
